import { ArrowDownLeftIcon } from '@/ui/svgs/arrow-down-left-icon';
import { ArrowUpRightIcon } from '@/ui/svgs/arrow-up-right';
import { SyncIcon } from '@/ui/svgs/sync-icon';

import { cn } from '@/lib/utils';

import { TransferDirection } from '../types';

export const getIconBasedOnTransactionType = ({
  direction,
  className,
}: {
  direction: TransferDirection;
  className: string;
}) => {
  switch (direction) {
    case 'INWARD':
      return (
        <ArrowDownLeftIcon
          className={cn('text-feedback-success-filled-default', className)}
        />
      );
    case 'EXCHANGE':
      return <SyncIcon className={cn(className)} />;
    case 'OUTWARD':
      return <ArrowUpRightIcon className={cn(className)} />;
    default:
      return <ArrowUpRightIcon className={cn(className)} />;
  }
};
