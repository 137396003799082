'use client';

import { SupportFAQs } from '@/features/shared/support/shared/components';

import { Column } from '@/ui/components/flex';
import { Text } from '@/ui/components/text';

import { SupportWidgets } from '../../shared/components/support-widgets';

export function SupportWidgetsWithFaqs() {
  return (
    <>
      <SupportWidgets />
      <Column gap="units-unit32">
        <Text
          weight="heading"
          variant="header-default"
          color="content-heading-default"
        >
          Answers to our most asked questions
        </Text>
        <SupportFAQs />
      </Column>
    </>
  );
}
