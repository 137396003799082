import { SVGProps } from 'react';

export const SyncIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99935 3.33332C9.48901 3.33332 8.99301 3.39051 8.5171 3.49851C8.06826 3.60036 7.62185 3.31907 7.52001 2.87024C7.41816 2.42141 7.69945 1.97501 8.14827 1.87316C8.74443 1.7379 9.3641 1.66666 9.99935 1.66666C14.6017 1.66666 18.3327 5.39761 18.3327 9.99999C18.3327 12.0683 17.5788 13.9605 16.3322 15.4167H17.0827C17.5429 15.4167 17.916 15.7897 17.916 16.25C17.916 16.7102 17.5429 17.0833 17.0827 17.0833H14.1466C13.6863 17.0833 13.3133 16.7102 13.3133 16.25V13.3333C13.3133 12.8731 13.6863 12.5 14.1466 12.5C14.6068 12.5 14.9799 12.8731 14.9799 13.3333V14.4317C16.0294 13.2533 16.666 11.7009 16.666 9.99999C16.666 6.31809 13.6813 3.33332 9.99935 3.33332ZM2.08268 3.76295C2.08268 3.30271 2.45578 2.92961 2.91602 2.92961H5.83268C6.29291 2.92961 6.66601 3.30271 6.66601 3.76295V6.66666C6.66601 7.12689 6.29291 7.49999 5.83268 7.49999C5.37245 7.49999 4.99935 7.12689 4.99935 6.66666V5.59012C3.96144 6.76588 3.33268 8.3096 3.33268 9.99999C3.33268 13.6819 6.31745 16.6667 9.99935 16.6667C10.5097 16.6667 11.0057 16.6095 11.4816 16.5015C11.9304 16.3997 12.3768 16.6809 12.4787 17.1297C12.5805 17.5786 12.2993 18.025 11.8504 18.1268C11.2543 18.2621 10.6346 18.3333 9.99935 18.3333C5.39697 18.3333 1.66602 14.6023 1.66602 9.99999C1.66602 7.93776 2.41546 6.05075 3.65543 4.59628H2.91602C2.45578 4.59628 2.08268 4.22318 2.08268 3.76295Z"
        fill="currentColor"
      />
    </svg>
  );
};
