'use client';

import { createContext, useContext } from 'react';

import { SignupValues } from '../types';

export const SignupContext = createContext(
  {} as {
    values: SignupValues;
    setValues: (values: Partial<SignupValues>) => void;
  },
);

export function useSignupContext() {
  const context = useContext(SignupContext);

  if (!context) {
    throw new Error('useSignupContext must be used within a SignupProvider');
  }

  return context;
}

export const SignupProvider = SignupContext.Provider;
