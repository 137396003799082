'use client';

import { FlagProvider } from '@unleash/nextjs/client';
import { PropsWithChildren } from 'react';

import { config } from '@/config';

export function UnleashFlagContextProvider({
  children,
}: Readonly<PropsWithChildren>) {
  return (
    <FlagProvider
      config={{
        url: `${config.UNLEASH_FRONTEND_API_URL}`,
        appName: 'nextjs',
        clientKey: config.UNLEASH_FRONTEND_API_TOKEN,
      }}
    >
      {children}
    </FlagProvider>
  );
}
