import GBFlag from '@/ui/svgs/flags/gb-flag';
import NGFlag from '@/ui/svgs/flags/ng-flag';
import { NigeriaFlagRound } from '@/ui/svgs/nigeria-flag-round';
import { GBFlagRound } from '@/ui/svgs/uk-flag-round-svg';

export type FlagSVGComponent = React.FC<React.SVGProps<SVGSVGElement>>;

// Add more imports as needed
interface FlagData {
  countryCode: string;
  icon: FlagSVGComponent; // Component representing the flag
  roundIcon: FlagSVGComponent;
  currency: string;
  symbol: string;
  name: string;
}

const flags: FlagData[] = [
  // Array of flag data objects
  {
    countryCode: 'GB',
    symbol: '£',
    currency: 'GBP',
    name: 'British Pound',
    icon: GBFlag,
    roundIcon: GBFlagRound,
  },
  {
    countryCode: 'NG',
    symbol: '₦',
    currency: 'NGN',
    name: 'Nigerian Naira',
    icon: NGFlag,
    roundIcon: NigeriaFlagRound,
  },
  // Add more flag objects as needed
];

type FlagDataMap = Record<string, FlagData>;

export const countryFullNameToCodeMap: Record<string, string> = {
  // Add country full names and their corresponding country codes
  'United Kingdom': 'GB',
  Nigeria: 'NG',
  // Add more country mappings as needed
};

export const countryCodeToFullNameMap: Record<string, string> = {
  // Add country codes and their corresponding full names
  GB: 'United Kingdom',
  NG: 'Nigeria',
  // Add more country mappings as needed
};

interface FlagDataMapAccumulator {
  countryToFlagMap: FlagDataMap;
  currencyToFlagMap: FlagDataMap;
}

export const { countryToFlagMap, currencyToFlagMap }: FlagDataMapAccumulator =
  flags.reduce(
    (acc: FlagDataMapAccumulator, flag) => {
      acc.countryToFlagMap[flag.countryCode] = flag;
      acc.currencyToFlagMap[flag.currency] = flag;
      return acc;
    },
    { countryToFlagMap: {}, currencyToFlagMap: {} },
  );

export default flags;
