import { isNil } from 'lodash';

import { type Profile } from '@/shared/types';

import { getLocalStorageItem, setLocalStorageItem } from '@/lib/utils';

export type AccountLog = Record<string, Partial<Profile>> | null;

export const useAccountLog = () => {
  const key = 'account-log';

  const accountLog: AccountLog = getLocalStorageItem(key) as AccountLog;

  const getProfile = (username?: string) => {
    if (isNil(username)) {
      return;
    }

    return accountLog?.[username];
  };

  const setProfile = (username: string, profile: Partial<Profile>) => {
    setLocalStorageItem(key, {
      ...accountLog,
      [username]: profile,
    });
  };

  const removeProfile = (username: string) => {
    const updatedAccountLog = getLocalStorageItem(key) as AccountLog;
    if (updatedAccountLog?.[username]) {
      delete updatedAccountLog[username];
    }
    setLocalStorageItem(key, updatedAccountLog);
  };

  return { accountLog, getProfile, setProfile, removeProfile };
};
