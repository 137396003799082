'use client';

import { createContext, useContext } from 'react';

import { Profile } from '@/shared/types';

export type ProfileContextValue = {
  profile: Profile;
  setProfile: (profile: Profile) => void;
};

export const ProfileContext = createContext({} as ProfileContextValue);

export const useProfileContext = () => useContext(ProfileContext);

export const ProfileProvider = ({
  value,
  children,
}: {
  children: React.ReactNode;
  value: ProfileContextValue;
}) => (
  <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
);
