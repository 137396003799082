'use client';

import { useRouter } from 'next/navigation';
import { useLayoutEffect } from 'react';

interface UseDisableBackOptions {
  isEnabled?: boolean;
}

export const useDisableBack = ({
  isEnabled = false, // deafult to false, so dont accidentlaly disable back even we add this hook
}: UseDisableBackOptions = {}) => {
  const router = useRouter();
  useLayoutEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      event.preventDefault();
      window.history.go(1);
    };
    if (!isEnabled) return;
    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [router, window.location.href, isEnabled]);
};
