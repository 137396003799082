import { defineVariants } from '@/ui/system';

export type CardIconVariants = typeof cardIconVariants;

export const cardIconVariants = defineVariants({
  base: 'inline-flex items-center justify-center flex-shrink-0',
  variants: {
    variant: {
      plain: '',
      opaque: 'bg-white inline-flex items-center justify-center p-1',
    },
    radius: {
      default: 'rounded-radius5',
      full: 'rounded-full',
    },
    appearance: {
      neutral:
        'text-feedback-neutral-ghost-text [&>svg]:text-feedback-neutral-ghost-text !border-ui-border-alternative',
      primary:
        'text-feedback-information-filled-default [&>svg]:text-feedback-information-filled-default !border-moniepoint-primary-l2',
      danger:
        'text-feedback-danger-filled-default [&>svg]:text-feedback-danger-filled-default !border-semantic-red-l3',
      success:
        'text-semantic-green-base [&>svg]:text-semantic-green-base !border-semantic-green-l3',
    },
    size: {
      small: '',
      medium: '',
      large: '',
      'extra-large': '',
    },
    stroke: {
      none: '',
      light: 'border-units-unit2',
      medium: 'border-units-unit4',
      heavy: 'border-units-unit6',
    },
  },
  compoundVariants: [
    // plain & small
    {
      size: 'small',
      variant: 'plain',
      class: '[&>svg]:w-5 h-5',
    },
    // plain & medium
    {
      size: 'medium',
      variant: 'plain',
      class: '[&>svg]:w-6 h-6',
    },
    // plain & large
    {
      size: 'large',
      variant: 'plain',
      class: '[&>svg]:w-7 h-7',
    },
    // opaque & small
    {
      size: 'small',
      variant: 'opaque',
      class: '!size-min [&>svg]:w-5 h-5',
    },
    // opaque & medium
    {
      size: 'medium',
      variant: 'opaque',
      class: '!size-min [&>svg]:w-6 h-6',
    },
    // opaque & large
    {
      size: 'large',
      variant: 'opaque',
      class: '!size-min [&>svg]:w-7 h-7',
    },
  ],
  defaultVariants: {
    variant: 'plain',
    radius: 'full',
    appearance: 'neutral',
    size: 'medium',
  },
});
