import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { useSuppportModal } from '@/features/shared/support/modal';

import { HelpCircleIcon } from '@/ui/svgs/help-circle-icon';
import { LogoutIcon } from '@/ui/svgs/logout-icon';
import { SettingsIcon } from '@/ui/svgs/settings-icon';
import { createComponent } from '@/ui/system';

import { ROUTES } from '@/shared/routes';

import { cn } from '@/lib/utils';

import { Button } from '../button';
import { DropdownMenuItem } from '../dropdown-menu';
import { Column } from '../flex';
import { Navlink } from '../navlink';
import { Text } from '../text';

export const ProfileOptionsDropdown = createComponent(
  ({ name, email, className, as = Column, ...props }, ref) => {
    const pathname = usePathname();
    const RootElement = as;
    const { toggleSupportModal, isSupportModalOpen } = useSuppportModal();

    return (
      <RootElement
        ref={ref}
        role="dropdown"
        aria-label="profile-options-dropdown"
        className={cn(
          'w-[244px] z-[100] bg-white p-0 flex flex-col pb-4 shadow-[0px_0px_20px_-8px_rgba(0,0,0,0.15)] rounded-units-unit20 border gap-y-units-unit10 overflow-hidden',
          className,
        )}
        {...props}
      >
        <Column className="p-5 border-b border-ui-border-alternative w-full  ">
          <Text
            as="h4"
            className="typography-body-large-untrimmed font-semibold text-content-heading-alternative text-ellipsis overflow-x-hidden"
          >
            {name}
          </Text>
          {email && (
            <Text
              as="p"
              className="typography-body-default-untrimmed font-medium text-content-subtext-default text-ellipsis overflow-x-hidden"
            >
              {email}
            </Text>
          )}
        </Column>

        <Column className="px-3 w-full gap-1" align="stretch">
          <DropdownMenuItem className="py-units-unit10 px-units-unit8 cursor-pointer">
            <Navlink
              onClick={() => isSupportModalOpen && toggleSupportModal()}
              as={Link}
              aria-label="settings-link"
              variant="link"
              appearance="plain"
              startIcon={
                <SettingsIcon className="!w-units-unit20 !h-units-unit20" />
              }
              href={ROUTES.SETTINGS.$PROFILE(
                pathname === ROUTES.SETTINGS.PROFILE ? '' : pathname,
              )}
              className={
                'w-full justify-start typography-body-default gap-x-units-unit10 font-medium text-content-subtext-default'
              }
            >
              Settings
            </Navlink>
          </DropdownMenuItem>

          {!isSupportModalOpen && (
            <DropdownMenuItem className="py-units-unit10 px-units-unit8 cursor-pointer">
              <Button
                onClick={toggleSupportModal}
                aria-label="support-link"
                variant="link"
                appearance="plain"
                startIcon={
                  <HelpCircleIcon className="!w-units-unit20 !h-units-unit20" />
                }
                className={{
                  root: 'w-full justify-start typography-body-default gap-x-units-unit10 font-medium text-content-subtext-default no-underline',
                }}
              >
                Support
              </Button>
            </DropdownMenuItem>
          )}

          <DropdownMenuItem className="py-units-unit10 px-units-unit8 cursor-pointer">
            <Navlink
              onClick={() => isSupportModalOpen && toggleSupportModal()}
              as={Link}
              aria-label="logout-link"
              variant="link"
              appearance="plain"
              startIcon={
                <LogoutIcon className="!w-units-unit20 !h-units-unit20" />
              }
              href={ROUTES.LOGOUT}
              className={
                'w-full justify-start typography-body-default gap-x-units-unit10 font-medium text-content-subtext-default'
              }
            >
              Sign out
            </Navlink>
          </DropdownMenuItem>
        </Column>
      </RootElement>
    );
  },
);
