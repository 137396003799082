'use client';

import { useHttpMutation } from '@/shared/hooks';

import { identityApi } from '@/lib/api';

export const useSignoutMutationWrapper = useHttpMutation<void, void>;

export function useSignoutMutation(
  options?: Parameters<typeof useSignoutMutationWrapper>[0],
) {
  return useSignoutMutationWrapper({
    api: identityApi,
    url: '/logouts',
    isAuthorized: true,
    disableErrorToast: true,
    ...options,
  });
}
