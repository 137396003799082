'use client';

import { Button, Column, Form, FormState } from '@kamona/components';
import React, { FC, useEffect } from 'react';

import { PinInput } from '@/ui/components/otp-input';

import { ROUTES } from '@/shared/routes';
import { Profile } from '@/shared/types';

import { Link } from '@/lib/navigation';

import { useSignInForm } from '../hooks/use-sign-in-form';
import { useSigninFormConfigContext } from '../providers';

interface WelcomeBackFormProps {
  data: Partial<Profile>;
}
export const WelcomeBackForm: FC<WelcomeBackFormProps> = ({ data }) => {
  const { config, setConfig } = useSigninFormConfigContext();
  const { passcodeLength = 6 } = config.passcodeVerification;

  useEffect(() => {
    setConfig({
      ...config,
      data: {
        ...config.data,
        username: data.phone,
      },
    });
  }, []);

  const { handleSignIn } = useSignInForm({
    passcodeLength,
  });

  const onSubmitHandler = (formValues: Record<string, any>) => {
    handleSignIn(formValues.passcode, data.phone);
  };

  return (
    <Form
      onSubmit={onSubmitHandler}
      validationMode="onChange"
      className="flex flex-grow justify-start"
    >
      <Column className="w-full flex-grow justify-between md:flex-grow-0 gap-y-units-unit40 md:justify-start">
        <Column as="fieldset" gap="units-unit16">
          <PinInput
            length={passcodeLength}
            className={{ root: 'justify-between' }}
            name="passcode"
            variant="filled"
            showValidationMessage={false}
            size="large"
            label="Passcode"
            isClearable={false}
            masked
            pattern={{
              value: /^\d{6}$/,
              message: 'Enter 6 digit passcode',
            }}
            wrapperClassName="gap-y-units-unit24"
          />
          <Link
            href={ROUTES.PASSCODE_RECOVERY.INDEX}
            className={
              'text-moniepoint-primary-l7 font-medium text-body-default'
            }
          >
            Forgot your passcode?
          </Link>
        </Column>
        <Column gap="units-unit16">
          <FormState>
            {(formState) => {
              return (
                <Button
                  size="medium"
                  type="submit"
                  variant="filled"
                  appearance="primary"
                  disabled={!formState.isValid}
                >
                  Continue
                </Button>
              );
            }}
          </FormState>
        </Column>
      </Column>
    </Form>
  );
};
