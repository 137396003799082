import { Chip, ChipProps } from '@kamona/components';

import { cn } from '@/lib/utils';

export type BaseChipSelectProps<T> = React.HTMLAttributes<HTMLDivElement> & {
  value?: T | T[];
  chipProps?: ChipProps;
  selectedChipProps?: ChipProps;
  disableSelectedState?: boolean;
  onValueChange?: (value: T) => void;
};

export interface ChipSelectOptionProps<T> {
  options: T[];
  getOptionLabel: (option: T) => any;
  isOptionSelected: (option: T) => boolean;
  isOptionDisabled?: (option: T) => boolean;
}

export type ChipSelectProps<T> = BaseChipSelectProps<T> &
  ChipSelectOptionProps<T>;

export function ChipSelect<T>({
  value,
  options,
  className,
  chipProps,
  onValueChange,
  getOptionLabel,
  isOptionSelected,
  isOptionDisabled,
  selectedChipProps,
  disableSelectedState = false,
  ...props
}: ChipSelectProps<T>) {
  return (
    <div {...props} className={cn('flex flex-wrap gap-units-unit8', className)}>
      {options.map((option, index) => {
        const isSelected = isOptionSelected(option);
        const showAsSelected = isSelected && !disableSelectedState;

        let actualChipProps = chipProps;

        if (showAsSelected) {
          actualChipProps = {
            ...chipProps,
            ...selectedChipProps,
          };
        }

        return (
          <Chip
            key={index}
            role="button"
            type="action"
            radius="full"
            variant="outline"
            onClick={() => onValueChange?.(option)}
            selected={showAsSelected}
            aria-disabled={isOptionDisabled?.(option)}
            appearance="neutral"
            {...actualChipProps}
          >
            {getOptionLabel(option)}
          </Chip>
        );
      })}
    </div>
  );
}
