import { notification } from '@kamona/components';
import { useEffect, useState } from 'react';

import {
  Alert,
  AlertContent,
  AlertDescription,
  AlertIcon,
} from '@/ui/components/alert';
import { Button } from '@/ui/components/button';
import { CountryCombobox } from '@/ui/components/country-combobox';
import { Column } from '@/ui/components/flex';
import { Text } from '@/ui/components/text';
import { InfoIcon } from '@/ui/svgs/info.svg';

import { useAnalytics } from '@/lib/analytics-service';

import { supportedCountries } from './supported-countries';

interface PassportCountrySelectorProps {
  onContinue: (value: string) => void;
}

export const PassportCountrySelector = ({
  onContinue,
}: PassportCountrySelectorProps) => {
  const analytics = useAnalytics();

  const [selectedCountry, setSelectedCountry] = useState('NG');
  const [isContinueDisabled, setIsContinueDisabled] = useState<boolean>(false);

  useEffect(() => {
    analytics.track({
      name: 'passport_country_initiated',
      data: {},
    });
  }, []);

  const handleSelectCountry = (value: string) => {
    setSelectedCountry(value);
  };

  const getCountryAlpha3Code = (value: string) => {
    const matchedCountry = supportedCountries.find(
      (supportedCountry) => supportedCountry.alpha2Code === value,
    );
    return matchedCountry?.alpha3Code ?? '';
  };

  const handleContinue = () => {
    if (selectedCountry) {
      setIsContinueDisabled(true);
      onContinue(getCountryAlpha3Code(selectedCountry));
    } else {
      notification.error({
        title: 'Please select a country!',
        position: 'top-center',
        variant: 'filled',
      });
    }
  };

  return (
    <Column className="w-full gap-y-units-unit32">
      <Text
        align="center"
        weight="heading"
        variant="header-default"
        color="content-heading-default"
      >
        Choose your passport
      </Text>
      <CountryCombobox
        options={['NG']}
        value={selectedCountry}
        onValueChange={handleSelectCountry}
      />

      <Alert
        variant="default"
        className="bg-moniepoint-primary-l2 py-units-unit14"
      >
        <AlertIcon renderIcon={() => <InfoIcon />} />
        <AlertContent>
          <AlertDescription
            as="p"
            color="content-heading-default"
            variant="body-small"
            className="text-moniepoint-primary-l7"
          >
            We just need the page of your passport that includes your photo and
            details.
          </AlertDescription>
        </AlertContent>
      </Alert>
      <Button onClick={handleContinue} disabled={isContinueDisabled}>
        {isContinueDisabled ? 'Loading...' : 'Continue'}
      </Button>
    </Column>
  );
};
