import { useHttpQuery } from '@/shared/hooks';

import { FeedbackType } from '../types';

export const useGetFeedbackTypesQuery = useHttpQuery<never, FeedbackType[]>;

export type UseGetFeedbackTypesOptions = Partial<
  Parameters<typeof useGetFeedbackTypesQuery>[0]
>;

export function useGetFeedbackTypes({
  ...options
}: UseGetFeedbackTypesOptions = {}) {
  return useGetFeedbackTypesQuery({
    retry: false,
    url: '/profile/v1/feedback-types',
    queryKey: ['feedbackTypes'],
    ...options,
  });
}
