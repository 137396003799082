'use client';

import { XIcon } from 'lucide-react';

import { useProfileContext } from '@/features/shared/profile/providers';

import { useManagedNavigation, useSearchParams } from '@/shared/hooks';
import { ROUTES } from '@/shared/routes';

import { HeaderIconButton } from './header-icon-button';

export function HeaderCloseIconButton({
  children,
  onClose,
  insideModal = false,
}: {
  children?: React.ReactNode;
  onClose?: () => void;
  insideModal?: boolean;
}) {
  const { profile } = useProfileContext();
  const { goBack, navigate } = useManagedNavigation();
  const { getParam } = useSearchParams();

  const handleClick = () => {
    if (insideModal && onClose) {
      onClose();
      return;
    }
    const source = getParam('source');
    if (source) {
      navigate(source, true); // Use replace navigation
    } else {
      /**
       * this is necessary to redirect to signin if user is not logged in
       * as it will create a ciruclar navigation to signin once we logged in
       */
      if (!profile?.username) {
        goBack(ROUTES.SIGN_IN.INDEX);
      } else {
        goBack(ROUTES.DASHBOARD.INDEX);
      }
    }
  };

  return (
    <HeaderIconButton onClick={handleClick}>
      {children || <XIcon />}
    </HeaderIconButton>
  );
}
