import { useHttpMutation } from '@/shared/hooks';
import { type BaseProfile, type Profile } from '@/shared/types';

import { API_CONFIG } from '@/lib/api-config';

export const useUpdateProfileMutation = useHttpMutation<
  Partial<BaseProfile>,
  Profile
>;

export function useUpdateProfile(
  options?: Parameters<typeof useUpdateProfileMutation>[0],
) {
  return useUpdateProfileMutation({
    isAuthorized: true,
    method: API_CONFIG.PROFILE.UPDATE.method,
    ...options,
  });
}
