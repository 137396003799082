'use client';

import { useHttpMutation } from '@/shared/hooks';
import { type Profile } from '@/shared/types';

import { API_CONFIG } from '@/lib/api-config';

export const useCreateProfileMutation = useHttpMutation<undefined, Profile>;

export function useCreateProfile(
  options?: Parameters<typeof useCreateProfileMutation>[0],
) {
  return useCreateProfileMutation({
    isAuthorized: true,
    ...API_CONFIG.PROFILE.CREATE,
    ...options,
  });
}
