import { Button, ButtonProps } from '@kamona/components';

import { KamonaSpinner } from '@/ui/svgs/kamona-spinner';

interface ButtonWithLoadingStateProps extends ButtonProps {
  loading: boolean;
}

export const ButtonWithLoadingState = ({
  loading,
  children,
  disabled,
  size = 'large',
  ...props
}: ButtonWithLoadingStateProps) => {
  const spinnerSize = {
    large: 'w-units-unit24 h-units-unit24',
    medium: 'w-units-unit20 h-units-unit20',
    small: 'w-units-unit16 h-units-unit16',
    tiny: 'w-units-unit12 h-units-unit12',
  }[size];

  return (
    <Button {...props} size={size} disabled={disabled || loading}>
      {loading ? <KamonaSpinner className={spinnerSize} /> : children}
    </Button>
  );
};
