'use client';

import { ArrowLeftIcon } from '@/ui/svgs/arrow-left-icon';

import { useMediaQuery } from '@/shared/hooks';

import { useRouter } from '@/lib/navigation';
import { cn } from '@/lib/utils';

import { HeaderIconButton } from './header-icon-button';

export function HeaderBackIconButton({
  onClick,
  children,
}: Readonly<{
  onClick?: () => void;
  children?: React.ReactNode;
}>) {
  const router = useRouter();
  const isDesktop = useMediaQuery('md');

  return (
    <HeaderIconButton
      onClick={onClick ?? router.back}
      className={{
        root: cn(!isDesktop && 'bg-transparent active:bg-transparent'),
      }}
    >
      {children || <ArrowLeftIcon data-testid="arrow-left-icon" />}
    </HeaderIconButton>
  );
}
