'use client';

import { useHttpMutation } from '@/shared/hooks';

import { EddClearancePayload, EddClearanceResponse } from '../types';

const useEddClearanceMutation = useHttpMutation<
  EddClearancePayload,
  EddClearanceResponse
>;

export const useEddClearance = (
  options?: Parameters<typeof useEddClearanceMutation>[0],
) => {
  return useEddClearanceMutation({
    ...options,
    method: 'POST',
    isAuthorized: true,
  });
};
