'use client';

import { AlertModal, AlertModalProps } from '@kamona/components';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';

export type CreateAlertDialogProps = Omit<AlertModalProps, 'children'> & {
  children?: React.ReactNode | ((props: AlertModalProps) => React.ReactNode);
};

export const useAlertDialog = () => {
  const [container, setContainer] = React.useState<HTMLDivElement | null>(null);
  const rootRef = React.useRef<ReactDOM.Root | null>(null);

  function onClose() {
    if (rootRef.current) {
      rootRef.current.unmount();
      rootRef.current = null;
    }

    if (container) {
      document.body.removeChild(container);
      setContainer(null);
    }
  }

  useEffect(() => {
    return () => {
      onClose();
    };
  }, []);

  const createAlertDialog = ({
    children,
    ...props
  }: CreateAlertDialogProps) => {
    const div = document.createElement('div');
    document.body.appendChild(div);
    setContainer(div);

    const root = ReactDOM.createRoot(div);
    rootRef.current = root;

    root.render(
      <AlertModal size="small" onClose={onClose} defaultOpen {...props}>
        {typeof children === 'function'
          ? children({ ...props, onClose })
          : children}
      </AlertModal>,
    );
  };

  return { createAlertDialog };
};
