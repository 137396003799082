/* eslint-disable no-console */
import SumsubWebSdkProps from '@sumsub/websdk-react/types/src/SumsubWebSdkProps';
import {
  AnyEventName,
  EventPayload,
  SnsError,
  WebSDKEvents,
} from '@sumsub/websdk/types/types';
import { merge } from 'lodash';

import { baseApi } from '@/lib/api';
import { debugLog } from '@/lib/utils';
import {
  DocumentVerificationConfig,
  IncomeVerificationPayload,
  LikenessCheckPayload,
  LivenessCheckConfig,
  VerificationServiceProvider,
} from '@/lib/verification-service/types';
import { CustomEventEmitter } from '@/lib/verification-service/util';

import { config } from '@/config';

interface KycInfo {
  kycId: string;
  requiresDocuments: boolean;
  passed: boolean;
}

interface ReviewResult {
  reviewAnswer: 'GREEN' | 'YELLOW' | 'RED';
}

export type ApplicationStateChangedPayload =
  WebSDKEvents['idCheck.onApplicantStatusChanged'] & {
    reviewResult: ReviewResult;
  };

type Resolve = (value: Record<string, any>) => void;
type Reject = (reason: any) => void;
export class SumsubServiceProvider implements VerificationServiceProvider {
  emitter: CustomEventEmitter<any> | undefined;
  user: Record<string, any> | undefined;
  kycInfo: KycInfo[] | undefined;
  resolve?: Resolve;
  reject?: Reject;
  verificationType?: 'income' | 'liveliness' | 'likeness' | 'id' = undefined;

  syncUser(user: Record<string, any>): void {
    this.user = user;
  }

  setEmitter(emitter: CustomEventEmitter<any>): void {
    this.emitter = emitter;
  }

  private handleError(error: Error, resolve: Resolve, reject: Reject) {
    if (error.message === 'KYC Passed') {
      resolve({
        success: true,
        message: 'KYC verification already completed.',
      });
    } else {
      reject({
        success: false,
        message: error.message,
      });
    }
  }

  async performLiveliness({ country, documentType }: LivenessCheckConfig = {}) {
    this.verificationType = 'liveliness';
    return new Promise(async (resolve, reject) => {
      try {
        const { accessToken, expirationHandler } =
          await this.initialize('liveliness');

        const config =
          documentType && country
            ? {
                documentDefinitions: {
                  IDENTITY: {
                    country,
                    idDocType: documentType,
                  },
                },
              }
            : undefined;

        this.buildConfig(
          {
            accessToken: accessToken,
            expirationHandler: expirationHandler,
            config,
          },
          resolve,
          reject,
        );
      } catch (err) {
        this.handleError(err as Error, resolve, reject);
      }
    });
  }

  async performDocumentVerification({
    documentType,
    country = 'GBR',
    canSkip = true,
  }: DocumentVerificationConfig) {
    this.verificationType = 'id';
    return new Promise(async (resolve, reject) => {
      try {
        //Check if Kyc Level Already Passed
        await this.getKycLevel('identity');

        if (!documentType) {
          (this.resolve = resolve), (this.reject = reject);
          this.emitter?.emit('config', {
            action: 'select-id',
            canSkip,
            resolve,
          });
          return;
        }

        const { accessToken, expirationHandler } =
          await this.initialize('identity');

        this.buildConfig(
          {
            accessToken: accessToken,
            expirationHandler: expirationHandler,
            config: {
              documentDefinitions: {
                IDENTITY: {
                  country,
                  idDocType: documentType,
                },
              },
              autoSelectDocumentDefinitions: true,
            },
          },
          resolve,
          reject,
        );
      } catch (err) {
        this.handleError(
          err as Error,
          this.resolve ?? resolve,
          this.reject ?? reject,
        );
      }
    });
  }

  async performLikenessCheck(payload: LikenessCheckPayload) {
    this.verificationType = 'likeness';
    return new Promise(async (resolve, reject) => {
      try {
        let accessToken = payload.sumsubAccessToken;

        if (!accessToken) {
          const result = await this.get2FAccessToken(payload.twoFaId);
          if (!result) {
            reject(new Error('Unable to retrieve access token'));
            return;
          }

          accessToken = result;
        }

        this.buildConfig(
          {
            accessToken: accessToken,
            expirationHandler: () =>
              this.get2FAccessToken(payload.twoFaId).then((resp) => {
                return resp ?? '';
              }),
          },
          resolve,
          reject,
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  private async get2FAccessToken(twoFaId: string): Promise<string | undefined> {
    try {
      this.triggerLoading(true);
      const step = 'FACE_MATCH';
      const response = await baseApi.post(
        `/identity/v1/2fas/${twoFaId}/steps/${step}/start`,
      );
      return response.data.sumsubAccessToken;
    } catch (error) {
      throw error;
    } finally {
      this.triggerLoading(false);
    }
  }

  async performIncomeVerification(payload: IncomeVerificationPayload) {
    this.verificationType = 'income';
    return new Promise(async (resolve, reject) => {
      try {
        if (!payload.eddId) {
          reject(
            new Error('EDD3 ID is required to perform income verification'),
          );
        }

        const { token: accessToken, uploadActionId } =
          (await this.getEddAccessToken(payload.eddId)) ?? {};

        if (!accessToken) {
          reject(new Error('Unable to retrieve access token'));
          return;
        }

        this.buildConfig(
          {
            accessToken,
            eddId: payload.eddId,
            uploadActionId,
            expirationHandler: () =>
              this.getEddAccessToken(payload.eddId).then((resp) => {
                return resp?.token ?? '';
              }),
          },
          resolve,
          reject,
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  private async getEddAccessToken(
    eddId: string,
  ): Promise<{ token: string; uploadActionId: string | null } | undefined> {
    try {
      this.triggerLoading(true);
      const response = await baseApi.post(
        `/profile/v1/edds/${eddId}/sumsub-tokens`,
      );
      return response.data;
    } finally {
      this.triggerLoading(false);
    }
  }

  private async getKycLevel(type: 'liveliness' | 'identity') {
    this.kycInfo = await this.getKycInfo();

    let kycLevel = this.kycInfo?.find(
      config.ONBOARDING_VERSION === 'v2'
        ? (info) => {
            return info.kycId === 'gb-personal-2';
          }
        : (info) => {
            if (type === 'liveliness') {
              return info.requiresDocuments === false;
            }
            if (type === 'identity') {
              return info.requiresDocuments === true;
            }
          },
    );

    if (!kycLevel) {
      throw new Error('Unable to find KYC Level');
    }

    if (kycLevel.passed) {
      throw new Error('KYC Passed');
    }

    return kycLevel;
  }

  private async initialize(
    type: 'liveliness' | 'identity',
  ): Promise<{ accessToken: string; expirationHandler: () => Promise<any> }> {
    const kycLevel = await this.getKycLevel(type);

    const kycId = kycLevel?.kycId || '';
    const accessToken = await this.getAccessToken(kycId);
    const expirationHandler = () => {
      return this.getAccessToken(kycId);
    };

    return { accessToken, expirationHandler };
  }

  private buildConfig(
    config: SumsubWebSdkProps & {
      uploadActionId?: string | null;
      eddId?: string;
    },
    resolve: any,
    reject: any,
  ) {
    const defaultConfig: Partial<SumsubWebSdkProps> = {
      config: {
        lang: 'en',
        theme: 'light',
      },
      options: {
        addViewportTag: false,
        adaptIframeHeight: true,
      },

      onMessage: this.createOnMessageHandler(resolve, config),
      onError: (error: SnsError) => {
        if (this.reject) this.reject(error);
        else reject(error);
      },
    };

    const resolvedConfig = merge({}, defaultConfig, config);

    this.emitter?.emit('config', resolvedConfig);
  }

  private async getAccessToken(kycLevel: string) {
    try {
      this.triggerLoading(true);
      if (this.user) {
        const response = await baseApi.post('/profile/v1/sumsub-kycs', {
          kycId: kycLevel,
          profileId: this.user.id,
        });
        return response.data.token;
      } else {
        return undefined;
      }
    } catch (error) {
      //TODO: Handle error properly
      console.log(error);
    } finally {
      this.triggerLoading(false);
    }
  }

  private triggerLoading(loading: boolean) {
    this.emitter?.emit('loading', loading);
  }

  private async getKycInfo() {
    try {
      this.triggerLoading(true);
      if (this.user) {
        const response = await baseApi.get(
          `/profile/v1/profiles/${this.user.id}/kyc-info`,
        );
        return response.data;
      } else {
        return undefined;
      }
    } catch (error) {
      // TODO: Handle Error Properly
      console.log(error);
    } finally {
      this.triggerLoading(false);
    }
  }

  private async confirmIncomeVerificationDocumentUpload(
    payload: ApplicationStateChangedPayload,
    eddId?: string,
    uploadActionId?: string | null,
  ) {
    if (payload.reviewStatus !== 'completed') {
      return false;
    }

    try {
      this.triggerLoading(true);
      if (eddId) {
        await baseApi.post(`/profile/v1/edds/${eddId}/upload-confirmations`, {
          uploadActionId,
        });
        return true;
      } else {
        throw new Error('EDD ID is required to confirm document upload');
      }
    } catch (error) {
      console.log(error);
      return false;
    } finally {
      this.triggerLoading(false);
    }
  }

  private handleApplicantStatusChanged(
    payload: ApplicationStateChangedPayload,
    resolve: (value: Record<string, any>) => void,
  ) {
    if (
      payload.reviewStatus === 'completed' &&
      (payload.reviewResult.reviewAnswer === 'GREEN' ||
        (this.verificationType === 'income' &&
          payload.reviewResult.reviewAnswer === 'RED'))
    ) {
      if (this.resolve) this.resolve(payload);
      else resolve(payload);
    }
  }

  private createOnMessageHandler(
    resolve: (value: Record<string, any>) => void,
    {
      config,
      eddId,
      uploadActionId,
    }: Partial<
      SumsubWebSdkProps & { uploadActionId?: string | null; eddId?: string }
    > = {},
  ) {
    return (type: string, payload: EventPayload<AnyEventName>) => {
      debugLog(type, payload, this.verificationType);
      if (this.verificationType === 'id') {
        const docType = config?.documentDefinitions?.IDENTITY?.idDocType;

        if (type === 'idCheck.onStepInitiated') {
          this.emitter?.emit('documentVerificationCallback', {
            docType,
            status: 'IN_PROGRESS',
            payload,
          });
        }

        if (type === 'idCheck.onStepCompleted') {
          this.emitter?.emit('documentVerificationCallback', {
            docType,
            status: 'SUCCESS',
            payload,
          });
        }

        if (type === 'idCheck.onUploadError') {
          this.emitter?.emit('documentVerificationCallback', {
            docType,
            status: 'ERROR',
            payload,
          });
        }
      }

      const { levelName } = payload as ApplicationStateChangedPayload;

      if (
        (type === 'idCheck.onApplicantActionStatusChanged' ||
          (type === 'idCheck.onApplicantStatusChanged' &&
            levelName === 'EDD3POFBankStatementLevel')) &&
        this.verificationType === 'income'
      ) {
        this.confirmIncomeVerificationDocumentUpload(
          payload as ApplicationStateChangedPayload,
          eddId,
          uploadActionId,
        ).then((documentUploadConfirmed) => {
          if (documentUploadConfirmed) {
            this.handleApplicantStatusChanged(
              payload as ApplicationStateChangedPayload,

              resolve,
            );
          }
        });
      }

      if (
        type === 'idCheck.onApplicantStatusChanged' &&
        ['id', 'liveliness'].includes(this.verificationType ?? '')
      ) {
        this.handleApplicantStatusChanged(
          payload as ApplicationStateChangedPayload,
          resolve,
        );
      }

      if (
        type === 'idCheck.onApplicantActionStatusChanged' &&
        this.verificationType === 'likeness'
      ) {
        this.handleApplicantStatusChanged(
          payload as ApplicationStateChangedPayload,
          resolve,
        );
      }
    };
  }
}
