'use client';

import {
  AlertModalProps,
  Column,
  Form,
  FormState,
  PhoneNumberInput,
  Typography,
} from '@kamona/components';
import { DialogProps } from '@radix-ui/react-dialog';
import { SubmitHandler } from 'react-hook-form';

import { Card, CardIcon } from '@/ui/components/card';
import {
  DialogAction,
  DialogClose,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/ui/components/dialog';
import { countryOptionsForPhoneInput } from '@/ui/components/phone-input/phone-input-data';
import { SharedDialog } from '@/ui/components/shared-dialog';
import { CallMissedIcon } from '@/ui/svgs/call-missed-icon';
import { CallRingingIcon } from '@/ui/svgs/call-ringing-icon';

import { useDialogReset } from '@/shared/hooks';

import { noop } from '@/lib/utils';

import { useSupportCallForm } from '../hooks';

import { config } from '@/config';

export function CallBackDialog({
  open,
  onOpenChange,
  setAlertModalProps,
}: Readonly<DialogProps> & {
  setAlertModalProps: (props: AlertModalProps) => void;
}) {
  const {
    isSubmitting,
    isSuccess,
    isSupportAvailable,
    onSubmit: handleSubmit,
  } = useSupportCallForm({ setAlertModalProps });

  useDialogReset({ isSuccess, open, reset: noop, onOpenChange });

  const onSubmit: SubmitHandler<Record<string, any>> | undefined = ({
    phoneNumber,
  }) => {
    handleSubmit({ phoneNumber, isSupportAvailable });
  };

  return (
    <SharedDialog open={open} onOpenChange={onOpenChange}>
      <DialogHeader className="items-center">
        <DialogTitle>We are currently available</DialogTitle>
      </DialogHeader>
      <Card variant="outline">
        <CardIcon>
          {isSupportAvailable ? <CallRingingIcon /> : <CallMissedIcon />}
        </CardIcon>
        <Typography
          variant="body"
          color="content-subtext-default"
          className="flex-grow"
        >
          {isSupportAvailable
            ? 'Leave your phone number for a callback within 15-30min.'
            : 'Leave your phone number for a callback.'}{' '}
          <Typography
            as="span"
            untrimmed
            variant="body"
            weight="medium"
            color="content-heading-default"
          >
            Available {config.SUPPORT_AVAILABILITY}.
          </Typography>
        </Typography>
      </Card>
      <Form onSubmit={onSubmit} validationMode="onChange">
        <Column className="mt-5">
          <PhoneNumberInput
            inputMode="tel"
            label="Your phone number"
            defaultCountry="GB"
            name="phoneNumber"
            required={{ value: true, message: 'Phone number is required' }}
            size="large"
            supportedCountries={countryOptionsForPhoneInput}
          />
        </Column>
        <DialogFooter align="stretch">
          <DialogClose onClick={() => onOpenChange?.(false)}>
            Cancel
          </DialogClose>
          <FormState>
            {(formState) => {
              return (
                <DialogAction
                  type="submit"
                  disabled={isSubmitting || !formState.isValid}
                  loading={isSubmitting}
                >
                  Submit
                </DialogAction>
              );
            }}
          </FormState>
        </DialogFooter>
      </Form>
    </SharedDialog>
  );
}
