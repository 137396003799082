import { type Profile } from '@/shared/types';

import { countryCollection } from '@/lib/constants';

export const getPhoneNumberValueFromProfile = (profile: Profile) => {
  const { countryCode, number } = profile?.phoneInfo || {};
  const { callingCode } = countryCollection[countryCode] || {};

  if (!(countryCode && number && callingCode)) {
    return;
  }

  const phoneNumber = number.replace(`+${callingCode}`, '');

  return {
    selectedCountry: countryCode,
    countryCode: callingCode,
    number: phoneNumber,
  };
};
