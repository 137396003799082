import { type Profile } from '@/shared/types';

import { Edd, EddLevel } from '../types';

export const getEddStatus = (profile?: Profile) => {
  if (!profile || (profile?.edds ?? []).length === 0) {
    return {};
  }

  const { LEVEL_1_2, LEVEL_3 } = (profile.edds ?? []).reduce(
    (accumulator, edd: Edd) => {
      accumulator[edd.level] = edd;
      return accumulator;
    },
    {} as Record<EddLevel, Edd>,
  );

  return {
    EDD_LEVEL_1_2: LEVEL_1_2,
    EDD_LEVEL_3: LEVEL_3,
    EDD_LEVEL_1_2_COMPLETE: LEVEL_1_2?.status === 'COMPLETED',
  };
};
