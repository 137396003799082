import { SuccessDialogSearchParams as AddMoneySuccessParams } from '@/features/add-money/shared/components/success-dialog';
import { SearchParams as AddMoneySearchParams } from '@/features/add-money/types';
import { SuccessDialogSearchParams as SendMoneySuccessParams } from '@/features/send-money/components/success-dialog';
import { NewTransactionSearchParams } from '@/features/transaction/shared/hooks';

import { addSearchParamsUtil } from './hooks/use-search-params';
import {
  DashboardMessagingAlertType,
  DashboardMessagingDialogType,
} from './types';

/** Stringify all the values of an object's first level */
function stringifyParams(params: Record<any, any>) {
  const stringParams = Object.fromEntries(
    Object.entries(params).map(([key, value]) => [key, String(value)]),
  ) as Record<string, string>;

  return new URLSearchParams(stringParams).toString();
}

export const ROUTES = {
  GOODBYE: { INDEX: '/goodbye' },
  SIGN_IN: {
    INDEX: '/signin',
    get $INDEX() {
      return (redirect: string, fallback: string) => {
        const searchParams = addSearchParamsUtil({
          newParams: {
            redirect,
            fallback,
          },
        });
        return `${this.INDEX}${redirect ? '?' + searchParams.toString() : ''}`;
      };
    },
    PASSCODE_VERIFICATION: '/signin/confirm-passcode',
    get $PASSCODE_VERIFICATION() {
      return (fallback: string) => {
        const searchParams = addSearchParamsUtil({
          newParams: {
            fallback,
          },
        });
        return `${this.PASSCODE_VERIFICATION}?${searchParams.toString()}`;
      };
    },
  },
  get $SIGN_IN() {
    return (redirect?: string): string => {
      const searchParams = addSearchParamsUtil({
        newParams: {
          redirect,
        },
      });
      return `${this.SIGN_IN.INDEX}?${searchParams.toString()}`;
    };
  },
  TWO_FA: {
    INDEX: '/2fa',
    get $INDEX() {
      return (redirect: string, fallback: string) => {
        const searchParams = addSearchParamsUtil({
          newParams: {
            redirect,
            fallback,
          },
        });
        return `${this.INDEX}${redirect ? '?' + searchParams.toString() : ''}`;
      };
    },
    PASSCODE: '/2fa/pass_code',
    FACE_MATCH: '/2fa/face_match',
    OTP: '/2fa/otp',
  },
  PASSCODE_RECOVERY: {
    INDEX: '/recover-passcode',
    CREATE_PASSCODE: '/recover-passcode/create',
    CONFIRM_PASSCODE: '/recover-passcode/confirm',
    get $INDEX() {
      return (redirect: string, fallback: string): string => {
        const searchParams = addSearchParamsUtil({
          newParams: {
            redirect,
            fallback,
          },
        });
        return `${this.INDEX}${redirect ? '?' + searchParams.toString() : ''}`;
      };
    },
  },
  ACCOUNTS: {
    INDEX: '/accounts',
    ADD_MONEY: '/add',
    get $ADD_MONEY() {
      return (accountId: string, params?: AddMoneySearchParams) =>
        `${this.INDEX}/${accountId}${this.ADD_MONEY}${params ? '?' + stringifyParams(params) : ''}`;
    },
  },
  SIGN_UP: {
    INDEX: '/signup',
    get $INDEX() {
      return (inviteCode: string) => {
        const searchParams = addSearchParamsUtil({
          newParams: {
            inviteCode,
          },
        });
        return `${this.INDEX}?${searchParams.toString()}`;
      };
    },
    INVITEE: {
      INDEX: '/join',
      get $INDEX() {
        return (params: Record<string, any>) => {
          if (!params) return this.INDEX;
          const searchParams = addSearchParamsUtil({
            newParams: params,
          });
          return `${this.INDEX}?${searchParams.toString()}`;
        };
      },
      $CODE: (inviteCode: string) => `/join/${inviteCode}`,
    },
    WAITLIST: {
      INDEX: '/signup/waitlist',
      DONE: '/signup/waitlist/done',
      get $DONE() {
        return (alreadyJoined: string) => {
          const searchParams = addSearchParamsUtil({
            newParams: {
              alreadyJoined,
            },
          });
          return `${this.DONE}?${searchParams.toString()}`;
        };
      },
    },
    QUOTA_REACHED: '/signup/quota-reached',
    get $QUOTA_REACHED() {
      return (inviteCode: string) => {
        const searchParams = addSearchParamsUtil({
          newParams: {
            inviteCode,
          },
        });
        return `${this.QUOTA_REACHED}?${searchParams.toString()}`;
      };
    },
    PHONE_VERIFICATION: '/signup/phone-verification',
    PASSCODE: {
      CREATE: '/signup/passcode/create',
      CONFIRM: '/signup/passcode/confirm',
      CONFIRMED: '/signup/passcode/confirmed',
    },
  },
  DASHBOARD: {
    INDEX: '/dashboard',
    ACCOUNT_DETAILS: '/dashboard/account-details',
    get $INDEX() {
      return (
        searchParams:
          | {
              alert?: DashboardMessagingAlertType;
              dialog?: DashboardMessagingDialogType;
            }
          | AddMoneySuccessParams,
      ) => {
        const params = addSearchParamsUtil({ newParams: searchParams });
        if (!params?.size) return this.INDEX;
        return `${this.INDEX}?${params.toString()}`;
      };
    },
  },
  EARN: '/earn',
  INVITE: '/invite',
  PHONE_VERIFICATION: {
    INDEX: '/contact-verification/phone',
    get $INDEX() {
      return (source: string) => {
        const searchParams = addSearchParamsUtil({ newParams: { source } });
        return `${this.INDEX}?${searchParams.toString()}`;
      };
    },
  },
  EMAIL_VERIFICATION: {
    INDEX: '/contact-verification/email',
    get $INDEX() {
      return (source: string) => {
        const searchParams = addSearchParamsUtil({ newParams: { source } });
        return `${this.INDEX}?${searchParams.toString()}`;
      };
    },
  },
  ONBOARDING: {
    PERSONAL_INFORMATION: '/personal-information',
    PRIMARY_CONTACT_VERIFICATION: '/primary-contact-verification',
    ADDRESS_INFORMATION: '/address-information',
    KYC: {
      START_KYC: '/kyc/upgrade/start',
      get $START_KYC() {
        return (source: string) => {
          const searchParams = addSearchParamsUtil({
            newParams: {
              source,
              upgrade: 'true',
            },
          });
          return `${this.START_KYC}?${searchParams.toString()}`;
        };
      },
      UPGRADE: '/kyc/upgrade/id-verification',
      STATUS: '/kyc/upgrade/status',
      LIVELINESS: '/kyc/liveliness',
      ID_VERIFICATION: '/kyc/id-verification',
      SUCCESS: '/kyc/success',
    },
  },
  ONBOARDING_V2: {
    STAY_TUNED: '/v2/stay-tuned',
    COMPLETE: '/v2/onboarding-complete',
    PERSONAL_INFORMATION: '/v2/personal-information',
    PERSONAL_INFORMATION_INTERSTITIAL: '/v2/personal-information/verify',
    MOBILE_NUMBER_FORM: '/v2/personal-information/mobile-number',
    EMAIL_FORM: '/v2/personal-information/email',
    PRIMARY_CONTACT_VERIFICATION: '/v2/primary-contact-verification',
    ADDRESS_INFORMATION: '/v2/address-information',
    REVIEW_ADDRESS_INFORMATION: '/v2/address-information/review',
    KYC: {
      START_KYC: '/v2/kyc/upgrade',
      get $START_KYC() {
        return (source: string) => {
          const searchParams = addSearchParamsUtil({
            newParams: {
              source,
              upgrade: 'true',
            },
          });
          return `${this.START_KYC}?${searchParams.toString()}`;
        };
      },
      UPGRADE: '/v2/kyc/upgrade/level-2',
    },
  },
  EDD: {
    ANNUAL_INCOME: {
      INDEX: '/edd/annual-income',
      get $INDEX() {
        return (
          callback: string,
          flow: 'onboarding' | 'payment' = 'onboarding',
        ) => {
          const searchParams = addSearchParamsUtil({
            newParams: {
              callback,
              flow,
            },
          });
          return `${this.INDEX}?${searchParams.toString()}`;
        };
      },
    },
    SOURCE_OF_FUNDS: { INDEX: '/edd/source-of-funds' },
    BUSINESS_ACTIVITY: { INDEX: '/edd/business-activity' },
    COMPANY_DETAILS: { INDEX: '/edd/company-details' },
    COMPLETE: {
      INDEX: '/edd/complete',
      get $INDEX() {
        return (callback: string) => {
          const searchParams = addSearchParamsUtil({
            newParams: {
              callback,
            },
          });
          return `${this.INDEX}?${searchParams.toString()}`;
        };
      },
    },
    EMPLOYER_DETAILS: { INDEX: '/edd/employer-details' },
    INSTITUTION_DETAILS: { INDEX: '/edd/institution-details' },
    PENSION_PROVIDER: { INDEX: '/edd/pension-provider' },
    PROMPT_EDD_3: {
      INDEX: '/edd/prompt',
      get $INDEX() {
        return (source: string) => {
          const searchParams = addSearchParamsUtil({
            newParams: {
              source,
            },
          });
          return `${this.INDEX}?${searchParams.toString()}`;
        };
      },
    },
    UPLOAD_DOCUMENT: { INDEX: '/edd/upload-document' },
    ON_HOLD: { INDEX: '/edd/on-hold' },
  },
  TRANSACTIONS: {
    INDEX: '/transactions',
    get $INDEX() {
      return (params: SendMoneySuccessParams) => {
        const searchParams = addSearchParamsUtil({ newParams: params });
        return `${this.INDEX}?${searchParams.toString()}`;
      };
    },
    DETAILS: '/transactions/details',
    get $DETAILS() {
      return (
        id: string,
        params?: AddMoneySuccessParams | SendMoneySuccessParams,
      ) => {
        if (!params) return `${this.DETAILS}/${id}`;

        const searchParams = addSearchParamsUtil({ newParams: params });
        return `${this.DETAILS}/${id}?${searchParams.toString()}`;
      };
    },
    STATUS: '/transactions/status',
    get $STATUS() {
      return (transactionReference: string) =>
        `${this.STATUS}?reference=${transactionReference}`;
    },
    NEW: {
      RECIPIENT: '/transactions/new/recipient',
      get $RECIPIENT() {
        return (params: NewTransactionSearchParams) => {
          const searchParams = addSearchParamsUtil({ newParams: params });
          return `${this.RECIPIENT}?${searchParams.toString()}`;
        };
      },
      AMOUNT: '/transactions/new/amount',
      get $AMOUNT() {
        return (params: NewTransactionSearchParams) => {
          const searchParams = addSearchParamsUtil({ newParams: params });
          return `${this.AMOUNT}?${searchParams.toString()}`;
        };
      },
      REVIEW: '/transactions/new/review',
      get $REVIEW() {
        return (params: NewTransactionSearchParams) => {
          const searchParams = addSearchParamsUtil({ newParams: params });
          return `${this.REVIEW}?${searchParams.toString()}`;
        };
      },
    },
  },
  RECIPIENTS: {
    INDEX: '/recipients',
    ADD_RECIPIENT: '/recipients/new',
    EDIT_RECIPIENT: '/recipients/edit',
    get $EDIT_RECIPIENT() {
      return (params: { id: string }) => {
        const searchParams = addSearchParamsUtil({ newParams: params });
        return `${this.EDIT_RECIPIENT}?${searchParams.toString()}`;
      };
    },
    DETAILS: '/recipients/details',
    get $DETAILS() {
      return (id: string) => `${this.DETAILS}/${id}`;
    },
    TRANSACTION: '/recipients/transactions',
    get $TRANSACTIONS() {
      return (id: string) => `${this.TRANSACTION}/${id}`;
    },
  },
  SUPPORT: {
    INDEX: '/support',
    get $INDEX() {
      return (source: string) => {
        const searchParams = addSearchParamsUtil({
          newParams: {
            source,
          },
        });
        return `${this.INDEX}?${searchParams.toString()}`;
      };
    },
  },
  SETTINGS: {
    INDEX: '/settings',
    PROFILE: '/settings/profile',
    TRANSFER_LIMITS: '/settings/transfer-limits',
    SECURITY: '/settings/security',
    NOTIFICATIONS: '/settings/notifications',
    get $INDEX() {
      return (source: string) => {
        const searchParams = addSearchParamsUtil({
          newParams: {
            source,
          },
        });
        return `${this.INDEX}?${searchParams.toString()}`;
      };
    },
    get $PROFILE() {
      return (source: string) => {
        const searchParams = addSearchParamsUtil({
          newParams: {
            source,
          },
        });
        return `${this.PROFILE}?${searchParams.toString()}`;
      };
    },
  },
  LOGOUT: '/signout',
  TERMS_OF_USE: {
    INDEX: '/terms-of-use',
    get $INDEX() {
      return (newParams: { phoneNumber: string; source: string }) => {
        const searchParams = addSearchParamsUtil({
          newParams,
        });
        return `${this.INDEX}?${searchParams.toString()}`;
      };
    },
  },
  PRIVACY_POLICY: {
    INDEX: '/privacy-policy',
    get $INDEX() {
      return (newParams: { phoneNumber: string; source: string }) => {
        const searchParams = addSearchParamsUtil({
          newParams,
        });
        return `${this.INDEX}?${searchParams.toString()}`;
      };
    },
  },
} as const;
