import { ROUTES } from '@/shared/routes';
import { KycLevel, KycStatus } from '@/shared/types';

import { useRouter } from '@/lib/navigation';

import { useProfileContext } from '../providers';
import { useKycStatus } from './use-kyc-status';

export interface UseKycLevelOptions {
  /** Redirect to the onboarding if the KYC level is not identified */
  redirectIfNotIdentified?: boolean;
}

export function useKycLevel({
  redirectIfNotIdentified = false,
}: UseKycLevelOptions = {}) {
  const router = useRouter();
  const { profile } = useProfileContext();
  const { checkKycStatus } = useKycStatus();

  if (
    profile?.currentKyc?.kycId &&
    checkKycStatus(KycStatus.Verified, KycStatus.Pending)
  ) {
    return profile?.currentKyc.kycId;
  } else if (profile?.currentKyc?.kycId === KycLevel.Level2) {
    return KycLevel.Level1;
  }
  if (redirectIfNotIdentified) {
    router.push(ROUTES.ONBOARDING.PERSONAL_INFORMATION);
  }
  return null;
}
