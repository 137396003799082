'use client';

import {
  Column,
  PasswordInput,
  PasswordInputProps,
  TextInput,
  TextInputProps,
  useFormContext,
} from '@kamona/components';

import { cn } from '@/lib/utils';

import { ResendCode } from './resend-code';

interface PinInputProps extends PasswordInputProps, TextInputProps {
  onResend?: () => void;
  length?: number;
  enableResendInSeconds?: number;
  wrapperClassName?: string;
  masked?: boolean;
}

export function PinInput({
  value,
  length = 6,
  onResend,
  disabled = false,
  masked = false,
  enableResendInSeconds,
  wrapperClassName,
  ...props
}: Readonly<PinInputProps>) {
  const { setValue } = useFormContext();

  const handleChange = (value: string) => {
    if (!props.name) {
      return;
    }

    let finalValue = value;

    // Do not allow typing more than 6 characters
    if (value.length > 6) {
      finalValue = value.slice(0, 6);
    }

    // Do not allow typing any characters other than numbers
    if (!/^\d+$/.test(value)) {
      finalValue = value.replace(/\D/g, '');
    }

    setValue(props.name, finalValue);
  };

  const Input = masked ? PasswordInput : TextInput;

  return (
    <Column className={cn(wrapperClassName ?? 'gap-y-units-unit24')}>
      <Input
        autoFocus
        inputMode="numeric"
        maxLength={length}
        variant="filled"
        onChange={handleChange}
        required
        rightAddon={null}
        disabled={disabled}
        size="large"
        pattern={{
          message: 'Only numeric values are allowed',
          value: /^\d+$/,
        }}
        {...props}
      />
      {onResend && <ResendCode {...{ onResend, enableResendInSeconds }} />}
    </Column>
  );
}
