import { BaseHeader, BaseHeaderProps } from '../base-header';
import { HeaderBackIconButton, HeaderWhyWeAskChip } from '../components';

export type HeaderWithBackAndWhyWeAskProps = Omit<
  BaseHeaderProps,
  'leftElement' | 'rightElement'
> & { disableBackButton?: boolean };

export function HeaderWithBackAndWhyWeAsk({
  disableBackButton,
  ...props
}: Readonly<HeaderWithBackAndWhyWeAskProps>) {
  return (
    <BaseHeader
      {...props}
      leftElement={!disableBackButton && <HeaderBackIconButton />}
      rightElement={<HeaderWhyWeAskChip />}
    />
  );
}
