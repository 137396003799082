type CallbackFunction = (
  resolve: (value: void | PromiseLike<void>) => void,
  reject: (reason?: any) => void,
  ...args: any[]
) => any;

export const delayedPromise = async (
  callback: CallbackFunction,
  interval: number,
): Promise<void> => {
  await new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      callback(resolve, reject);
    }, interval);
  });
};
