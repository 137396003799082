import { useProfileContext } from '@/features/shared/profile/providers';

import { useSearchParams } from '@/shared/hooks';

import { useRouter } from '@/lib/navigation';
import { TwoFaService } from '@/lib/two-fa-service/two-fa-service';

import { ROUTES } from '../routes';

export const useRecoverPasscode = ({
  redirectFallback,
  redirectTo,
}: {
  redirectFallback: string;
  redirectTo: string;
}) => {
  const router = useRouter();

  const { getParam } = useSearchParams();
  const { profile } = useProfileContext();
  const twoFaService = TwoFaService.use2faService();

  const redirectUrl = ROUTES.PASSCODE_RECOVERY.CREATE_PASSCODE;
  const redirectFallbackUrl = ROUTES.PASSCODE_RECOVERY.INDEX;

  const onTwoFaSuccess = (id: string) => {
    if (!id) {
      return router.push(getParam('fallback') ?? redirectFallbackUrl);
    }
    router.push(redirectUrl);
  };

  const on2faExpiry = () =>
    router.push(getParam('fallback') ?? redirectFallbackUrl);

  const handleRecoverPasscode = ({
    username = profile.username,
  }: {
    username?: string;
  } = {}) => {
    TwoFaService.config = {
      OTP: {
        title: 'SMS code',
        showDeviceSpecificTitle: true,
        subtitle: `Verification code sent to`,
        passcodeLength: 6,
        showUsername: true,
      },
    };

    TwoFaService.onSuccess = onTwoFaSuccess;

    TwoFaService.onExpiry = on2faExpiry;

    twoFaService.create2faSession({
      username,
      mfaAction: 'CHANGE_PASSCODE',
      redirectFallback,
      redirectTo,
    });
  };

  return {
    handleRecoverPasscode,
  };
};
