import {
  DocumentVerificationConfig,
  IncomeVerificationPayload,
  LikenessCheckPayload,
  VerificationServiceProvider,
} from '../../types';
import { CustomEventEmitter } from '../../util';

export class MockServiceProvider implements VerificationServiceProvider {
  emitter: CustomEventEmitter<any> | undefined;
  user: Record<string, any> | undefined;

  performLiveliness(): Promise<Record<string, any>> {
    this.emitter?.emit('config', {
      type: 'liveliness',
    });

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({});
      }, 500);
    });
  }

  performDocumentVerification(
    config?: DocumentVerificationConfig,
  ): Promise<Record<string, any>> {
    this.emitter?.emit('config', {
      type: 'id-verification',
      payload: {
        ...config,
      },
    });

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({});
      }, 500);
    });
  }

  performLikenessCheck(payload: LikenessCheckPayload): Promise<any> {
    this.emitter?.emit('config', {
      type: 'likeness',
      payload,
    });

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({});
      }, 500);
    });
  }

  performIncomeVerification(payload: IncomeVerificationPayload): Promise<any> {
    this.emitter?.emit('config', {
      type: 'income-verification',
      payload,
    });

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({});
      }, 500);
    });
  }

  syncUser(user: Record<string, any>): void {
    this.user = user;
  }

  setEmitter(emiiter: CustomEventEmitter<any>): void {
    this.emitter = emiiter;
  }
}
