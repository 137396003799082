import { SVGProps } from 'react';

export const ArrowDownLeftIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.83398 14.1667L15.0007 5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M5.10961 9.16675C5.10961 9.16675 4.71387 13.8613 5.42629 14.5738C6.13873 15.2862 10.8333 14.8904 10.8333 14.8904"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
