'use client';

import { Avatar, Column } from '@kamona/components';
import { useEffect, useRef, useState } from 'react';

import {
  SigninForm,
  WelcomeBackForm,
} from '@/features/authentication/signin/components';

import { HeaderHelpChip } from '@/ui/components/header/components';
import { HeaderWithLogo } from '@/ui/components/header/variants';
import {
  Page,
  PageLoader,
  PageSubtitle,
  PageTitle,
} from '@/ui/components/page';

import { useAccountLog } from '@/shared/hooks/use-accounts-log';
import { ROUTES } from '@/shared/routes';
import { Profile } from '@/shared/types';
import { capitalizeString, getUserInitials } from '@/shared/utils';

import { LOCAL_STORAGE_KEYS } from '@/lib/constants';
import { Link } from '@/lib/navigation';
import { cn, getLocalStorageItem } from '@/lib/utils';

const welcomeBackPageContent = ({ profile }: { profile: Partial<Profile> }) => {
  const usernameInitials = getUserInitials({
    firstName: profile?.firstName ?? '',
    lastName: profile?.lastName ?? '',
  });

  return (
    <>
      <Column gap="units-unit40">
        <Avatar
          size="large"
          appearance="active"
          text={usernameInitials}
          shape="circle"
          position="left"
        />
        <PageTitle
          className="my-units-unit16"
          align={'left'}
          title={`Welcome back, ${capitalizeString(profile?.firstName ?? '')}`}
          subtitle={
            <PageSubtitle align={'left'} color={'content-subtext-default'}>
              Enter your secure 6-digit passcode.
              <br />
              Not {capitalizeString(profile?.firstName ?? '')}?{' '}
              <Link
                className={
                  'inline-block text-moniepoint-primary-l7 font-medium '
                }
                href={ROUTES.LOGOUT}
              >
                Log out
              </Link>
            </PageSubtitle>
          }
        />
      </Column>
      <WelcomeBackForm data={profile} />
    </>
  );
};

const signinPageContent = () => (
  <>
    <PageTitle
      align={'left'}
      title={'Log in'}
      subtitle={
        <PageSubtitle align={'left'} color={'content-subtext-default'}>
          Continue using your mobile number.
          <br />
          New to Moniepoint?{' '}
          <Link
            className={'inline-block text-moniepoint-primary-l7'}
            href={ROUTES.SIGN_UP.INDEX}
          >
            Sign up
          </Link>
        </PageSubtitle>
      }
    />

    <SigninForm />
  </>
);

export default function SigninPage() {
  const [showLoader, setShowLoader] = useState(true);
  const [showWelcomeBackContent, setShowWelcomebackContent] = useState(false);
  const { getProfile } = useAccountLog();
  const currentUser = getLocalStorageItem(LOCAL_STORAGE_KEYS.CURRENT_USER) as {
    id: string;
  } | null;

  const profile = useRef<Partial<Profile> | undefined | null>(null);

  useEffect(() => {
    if (currentUser?.id) {
      profile.current = getProfile(currentUser.id);
      if (profile?.current?.firstName && profile.current.phone) {
        setShowWelcomebackContent(true);
      }
    }
    setShowLoader(false);
  }, [currentUser]);

  if (showLoader) {
    return <PageLoader />;
  }
  return (
    <>
      <HeaderWithLogo
        rightElement={<HeaderHelpChip />}
        className="py-units-unit10 px-units-unit16 md:py-units-unit24 md:px-units-unit80"
      />
      <Page
        className={cn(
          'flex-grow justify-start gap-units-unit40 md:items-center',
          {
            'md:my-units-unit80': showWelcomeBackContent,
          },
        )}
      >
        {showWelcomeBackContent && profile.current
          ? welcomeBackPageContent({
              profile: profile.current as Partial<Profile>,
            })
          : signinPageContent()}
      </Page>
    </>
  );
}
