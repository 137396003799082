import { useHttpMutation } from '@/shared/hooks';

import {
  AddFeedbackRequestPayload,
  AddFeedbackRequestResponse,
} from '../types';

export const useAddFeedbackMutation = useHttpMutation<
  AddFeedbackRequestPayload,
  AddFeedbackRequestResponse
>;

export type UseAddFeedbackOptions = Partial<
  Parameters<typeof useAddFeedbackMutation>[0]
>;

export function useAddFeedback(options?: UseAddFeedbackOptions) {
  return useAddFeedbackMutation({
    method: 'POST',
    isAuthorized: true,
    url: '/profile/v1/feedbacks',
    ...options,
  });
}
