'use client';

import { Form, FormState, LinkButton } from '@kamona/components';
import Link from 'next/link';
import { SubmitHandler } from 'react-hook-form';

import { Column } from '@/ui/components/flex';
import { PinInput } from '@/ui/components/otp-input';
import { PageSubtitle, PageTitle } from '@/ui/components/page';

import { cn } from '@/lib/utils';

import { ButtonWithLoadingState } from '../button-with-loading-state';

export interface PasscodeVerificationFormProps {
  value: string;
  title: string;
  subtitle?: string | React.ReactNode;
  isKnownUser?: boolean;
  passcodeLength: number;
  onChange: (value: string) => void;
  onResend?: () => void;
  recoverPasscodeLink?: string;
  recoverPasscodeFn?: () => void;
  user?: {
    name: string;
    profileImageUrl?: string;
  };
  cancelUrl?: string;
  inputLabel?: string;
  inputMasked?: boolean;
  isButtonLoading?: boolean;
}

export function PasscodeVerificationForm({
  title,
  subtitle,
  passcodeLength,
  recoverPasscodeLink,
  recoverPasscodeFn,
  onChange,
  onResend,
  inputLabel: label,
  inputMasked: masked = true,
  isButtonLoading = false,
}: Readonly<PasscodeVerificationFormProps>) {
  const handleSubmit: SubmitHandler<Record<string, string>> = ({
    passcode,
  }) => {
    onChange(passcode);
  };

  return (
    <Form
      className={cn(
        'gap-units-unit32 md:gap-units-unit56 flex flex-col flex-grow justify-start w-full',
      )}
      onSubmit={handleSubmit}
    >
      <PageTitle
        align={'left'}
        title={title}
        subtitle={<PageSubtitle align={'left'}>{subtitle}</PageSubtitle>}
      />

      <FormState>
        {(formState) => (
          <Column
            gapY="units-unit40"
            className="flex-grow justify-between md:justify-start gap-y-units-unit32 md:gap-y-units-unit40"
          >
            <Column as="fieldset" gap="units-unit16">
              <PinInput
                aria-label="passcode-input"
                length={passcodeLength}
                name="passcode"
                variant="filled"
                showValidationMessage={false}
                size="large"
                label={label || '6-digit passcode'}
                onResend={onResend}
                enableResendInSeconds={30}
                isClearable={false}
                masked={masked}
                pattern={{
                  value: /^\d{6}$/,
                  message: 'Enter 6 digit passcode',
                }}
                wrapperClassName="gap-y-units-unit24"
              />
              {recoverPasscodeFn && (
                <LinkButton
                  role="link"
                  aria-label="recover-passcode-link"
                  onClick={recoverPasscodeFn}
                  className={{
                    root: 'text-moniepoint-primary-l7 font-medium text-body-default !justify-start',
                  }}
                >
                  Forgot your passcode?
                </LinkButton>
              )}
            </Column>
            <ButtonWithLoadingState
              disabled={!formState.isValid}
              loading={isButtonLoading}
              size="medium"
              type="submit"
            >
              Continue
            </ButtonWithLoadingState>
          </Column>
        )}
      </FormState>
    </Form>
  );
}
