import { HttpMethod } from '@/shared/types';

export const API_CONFIG = {
  PROFILE: {
    CREATE: {
      method: HttpMethod.Post,
      url: '/profile/v1/profiles',
    },
    UPDATE: {
      method: HttpMethod.Patch,
      url: (id: string) => `/profile/v1/profiles/${id}`,
    },
  },
};
