'use client';

import { jwtDecode } from 'jwt-decode';

import { STORAGE_KEYS } from '../constants';
import {
  debugLog,
  getExistingDeviceInfo,
  logError,
  restoreToken,
} from '../utils';
import { CustomEventEmitter } from '../verification-service/util';
import {
  AnalyticsEvent,
  AnalyticsProviderType,
  DeviceInfo,
  User,
} from './types';

export class AnalyticsService {
  private eventManager = new CustomEventEmitter();
  private providers: AnalyticsProviderType[] = [];
  private user?: User;

  constructor(providers: AnalyticsProviderType[]) {
    this.providers = providers;
  }

  private async trackServerEvent(event: AnalyticsEvent): Promise<void> {
    const token = restoreToken(STORAGE_KEYS.CLIENT_ACCESS_TOKEN_STORAGE_KEY);
    const deviceInfo = getExistingDeviceInfo();
    const payload = {
      name: event.name,
      data: {
        ...event.data,
        distinct_id: deviceInfo?.deviceId,
        device_id: deviceInfo?.deviceId,
        device_type: deviceInfo?.type,
        platform: 'web',
        ...(token && {
          ...this.user,
          distinct_id: (jwtDecode(token) as { username: string }).username,
        }),
      },
    };
    try {
      fetch('/api/v1/track', {
        method: 'POST',
        body: JSON.stringify(payload),
      });
    } catch (error) {
      logError(error);
    }
  }

  async track(event: AnalyticsEvent): Promise<void> {
    this.trackServerEvent(event);
    // TODO: this will be removed once we move Singular to Server side
    if (event.name && this?.providers?.length) {
      for (const provider of this.providers) {
        try {
          await provider.sendEvent(event);
        } catch (error) {
          debugLog(error);
        }
      }
    }
  }

  findProvider(name: string) {
    return this.providers.find((p) => {
      return p.constructor.name === name;
    });
  }

  async trackProvider(
    providerName: string,
    event: AnalyticsEvent,
  ): Promise<void> {
    const provider = this.findProvider(providerName);

    if (provider) {
      await provider.sendEvent(event);
    } else {
      throw new Error(`Provider ${providerName} not found`);
    }
  }

  async reset(): Promise<void> {
    for (const provider of this.providers) {
      await provider.reset();
    }
  }

  /**
   * syncUser vs identify
   * syncUser is adding information like phone, email
   * identify is adding information like user_id into the provider's identify methods to start their own identification flow i.e provider uses their internal methods like identify to start the user journey with unique id (typically user_id), this is different from syncUser because in sync User you can add user related information inside event data not in identification data
   */

  // Sync User ID after login with all providers
  identify(userId: string) {
    for (const provider of this.providers) {
      provider.identify(userId);
    }
  }

  // We need User to be synced with all providers
  syncUser(user: User) {
    this.user = { ...this.user, ...user };
    for (const provider of this.providers) {
      provider.syncUser(user);
    }
  }

  // We need DeviceInfo to be synced with all providers once user open webpage
  syncDeviceInfo(deviceInfo: DeviceInfo) {
    for (const provider of this.providers) {
      provider.deviceInfo = deviceInfo;
    }
  }

  pageVisit() {
    for (const provider of this.providers) {
      if (provider.pageVisit) {
        provider.pageVisit();
      }
    }
  }

  on(type: string, callback: (value: any) => void) {
    this.eventManager.on(type, callback);
  }

  off(type: string, callback: (value: any) => void) {
    this.eventManager.on(type, callback);
  }
}
