import { type KycStatus } from '@/shared/types';

import { useProfileContext } from '../providers';

export function useKycStatus() {
  const { profile: { currentKyc } = {} } = useProfileContext();

  const checkKycStatus = (...status: KycStatus[]): boolean => {
    return !!currentKyc && status.includes(currentKyc?.status);
  };

  return { status: currentKyc?.status, checkKycStatus };
}
