'use client';

import { AlertModalProps, Row, TextArea } from '@kamona/components';
import { DialogProps } from '@radix-ui/react-dialog';
import { Controller } from 'react-hook-form';

import {
  DialogAction,
  DialogClose,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/ui/components/dialog';
import { RadioGroup, RadioGroupItem } from '@/ui/components/radio-group';
import { SharedDialog } from '@/ui/components/shared-dialog';
import { Text } from '@/ui/components/text';
import { TextField } from '@/ui/components/text-field';

import { useDialogReset } from '@/shared/hooks';

import { useFeedbackForm, useGetFeedbackTypes } from '../hooks';
import { FeedbackType } from '../types';

export function FeedbackDialog({
  open,
  onOpenChange,
  setAlertModalProps,
}: Readonly<
  DialogProps & { setAlertModalProps: (props: AlertModalProps) => void }
>) {
  const { data: feedbackTypes } = useGetFeedbackTypes();

  const {
    control,
    handleSubmit,
    isSubmitting,
    formState: { errors, isValid },
    isSuccess,
    reset,
  } = useFeedbackForm({ setAlertModalProps });

  useDialogReset({ isSuccess, open, reset, onOpenChange });

  return (
    <SharedDialog open={open} onOpenChange={onOpenChange}>
      <DialogHeader>
        <DialogTitle>Leave a feedback</DialogTitle>
      </DialogHeader>
      <DialogDescription className="text-content-subtext-default">
        Your feedback helps create a better experience
      </DialogDescription>
      <form onSubmit={handleSubmit}>
        <Controller
          name="type"
          control={control}
          rules={{ required: 'Please select a feedback type' }}
          render={({ field }) => (
            <RadioGroup onValueChange={field.onChange} value={field.value}>
              {feedbackTypes?.map((option: FeedbackType) => (
                <Row key={option.type} align="center" gap="units-unit8">
                  <RadioGroupItem value={option.type} id={option.type} />
                  <Text
                    as="label"
                    htmlFor={option.type}
                    variant="body-default"
                    color="content-heading-default"
                    className="first-letter:capitalize"
                  >
                    {option.description}
                  </Text>
                </Row>
              ))}
            </RadioGroup>
          )}
        />
        {errors.type && <span>{errors.type.message}</span>}
        <Controller
          name="message"
          control={control}
          render={({ field }) => (
            <TextField
              className="mt-units-unit20"
              error={errors.message?.message}
              renderInput={(props) => (
                <TextArea
                  label="Describe your feedback"
                  {...field}
                  {...props}
                />
              )}
            />
          )}
        />
        <DialogFooter align="stretch">
          <DialogClose onClick={() => onOpenChange?.(false)}>
            Cancel
          </DialogClose>
          <DialogAction
            onClick={handleSubmit}
            disabled={isSubmitting || !isValid}
            loading={isSubmitting}
          >
            Submit feedback
          </DialogAction>
        </DialogFooter>
      </form>
    </SharedDialog>
  );
}
