'use client';

import { notification } from '@kamona/components';
import { parsePhoneNumber } from '@kamona/utils-v2';
import { SubmitHandler } from 'react-hook-form';

import { useRecoverPasscode } from '@/shared/hooks/use-recover-passcode';
import { ROUTES } from '@/shared/routes';
import { type PhoneInfoValue } from '@/shared/types';

import { useSigninFormConfigContext } from '../../providers/sign-in-form-context-provider';

export interface InitRecoverPasswordForm {
  phone: PhoneInfoValue;
}

export const useInitRecoverPasswordForm = () => {
  const redirect = ROUTES.PASSCODE_RECOVERY.INDEX;
  const { config } = useSigninFormConfigContext();

  const { handleRecoverPasscode } = useRecoverPasscode({
    redirectFallback: redirect,
    redirectTo: redirect,
  });

  const handleInitRecoverPasscode: SubmitHandler<Record<string, any>> = (
    data,
  ) => {
    const phoneInfo = parsePhoneNumber(data?.phoneNumber);

    if (!phoneInfo) {
      return notification.error({
        title: 'Invalid phone number',
        position: 'top-center',
        variant: 'filled',
      });
    }

    const { internationalNumber } = phoneInfo;

    handleRecoverPasscode({ username: internationalNumber });
  };

  return {
    initialValues: { phoneNumber: config?.data?.username ?? '' },
    handleInitRecoverPasscode,
  };
};
