import { SVGProps } from 'react';

export const ArrowUpRightIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.1667 5.83325L5 14.9999"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M9.16602 5.10961C9.16602 5.10961 13.8606 4.71387 14.5731 5.42629C15.2855 6.13873 14.8897 10.8334 14.8897 10.8334"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
