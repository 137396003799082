'use client';

import React from 'react';

import { useProfileContext } from '@/features/shared/profile/providers';
import { SupportWidgetsWithFaqs } from '@/features/shared/support/shared/components';

import { Page, PageCover, PageTitle } from '@/ui/components/page';
import SupportPageIllustration from '@/ui/svgs/support-page-illustration';

export const SupportPageComponent = () => {
  const { profile } = useProfileContext();
  return (
    <Page container="xl">
      <PageCover coverSvg={<SupportPageIllustration />} />
      <PageTitle
        title={`Hi ${profile?.firstName || ``}, how can we help you today?`}
      />
      <SupportWidgetsWithFaqs />
    </Page>
  );
};
