'use client';

import { useEffect } from 'react';

import {
  hideZendeskWidget,
  showZendeskWidget,
} from '@/features/shared/support/utils/zendesk';
import { config } from '../config';

export function ZendeskConfigScript() {
  useEffect(() => {
    // Create the script element
    if (!document.getElementById('ze-snippet')) {
      const script = document.createElement('script');
      script.id = 'ze-snippet';
      script.src =
        'https://static.zdassets.com/ekr/snippet.js?key='+config.ZENDESK_KEY;
      script.async = true;

      document.head.appendChild(script);
    }

    showZendeskWidget();

    return () => {
      hideZendeskWidget();
    };
  }, []);

  return null;
}
